<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5"></div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2 pb-0"></div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "widget-12"
};
</script>
